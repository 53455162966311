import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import ScrolldownHeader from "../components/organisms/ScrolldownHeader"
import GradientBackground from "../components/atoms/GradientBackground"
import BodyVitals from "../components/organisms/BodyVitals"
import Advertisement from "../components/organisms/Advertisement"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import Banner from "../components/organisms/Home"

const ContentContext = React.createContext()

const IndexPage = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "home_page" } } }
        ) {
          edges {
            node {
              frontmatter {
                header
                header_button
                video_src
                video_poster {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
                body_vitals {
                  header
                  body_paragraph_1
                  body_paragraph_2
                  list_elements {
                    name
                    icon_path
                    is_disabled
                  }
                }
                advertisement {
                  header
                  image_path {
                    childImageSharp {
                      gatsbyImageData(width: 480, placeholder: BLURRED)
                    }
                  }
                  description
                  button_text_1
                  button_link_1
                  button_text_2
                  button_link_2
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo title="Home" />
      
      {allMarkdownRemark.edges.map((item, i) => {
        let content = item.node.frontmatter

        return (
          <React.Fragment key={i}>
            <ContentContext.Provider value={content}>
            
              <ScrolldownHeader content={content} />
              {/* <Banner /> */}
              <BodyVitals content={content.body_vitals} />
              <GradientBackground topBar>
                <Advertisement
                  header={content.advertisement.header}
                  description={content.advertisement.description}
                  buttonColor="primary"
                  buttonText1={content.advertisement.button_text_1}
                  buttonLink1={content.advertisement.button_link_1}
                  buttonText2={content.advertisement.button_text_2}
                  buttonLink2={content.advertisement.button_link_2}
                  imagePath={content.advertisement.image_path}
                  underButton={content.advertisement.under_button}
                />
              </GradientBackground>
            </ContentContext.Provider>
          </React.Fragment>
        )
      })}
    </Layout>
  )
}

export default IndexPage
