import React, { useState, useEffect } from "react"
import styled from "styled-components"

import HeaderText from "../../atoms/HeaderText"
import P from "../../atoms/P"
import useMediaType from "../../hooks/useMediaType"

const Container = styled.div`
  display: flex;
  width: 100%;
  background: linear-gradient(
    85.42deg,
    #f4f4f4 22.6%,
    #f4f4f4 34.37%,
    #f4f4f4 44.41%,
    rgba(244, 244, 244, 0) 89.05%
  );
  padding-block: 62px;
  flex-direction: column;
  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    flex-direction: column;
    padding-block: 96px;
  }
  //desktop small
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    padding-block: 127px 136px;
    flex-direction: row;
  }
`
const TextWrapper = styled.div`
  //tablet
`

const HeaderWrapper = styled.div`
  p {
    font-size: 16px;
    line-height: 24px;
  }

  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    p {
      font-size: 22px;

      line-height: 32px;
    }
  }
  //desktop small
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    p {
      font-size: 30px;
      line-height: 38px;
    }
  }
`

const Paragraph1 = styled.div`
  p {
    font-size: 16px;
    line-height: 24px;
  }
  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    p {
      font-size: 18px;
    }
  }
  //desktop
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    p {
      font-size: 16px;
    }
  }
`
const Paragraph2 = styled.div`
  p {
    font-size: 16px;
    line-height: 24px;
  }
  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    p {
      font-size: 18px;
    }
  }
  //desktop
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    p {
      font-size: 16px;
    }
  }
`
const IconsList = styled.div`
  margin-top: 30px;
  display: grid;
  gap: 8px;
  row-gap: 50px;
  justify-items: center;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktop}px) {
    grid-template-columns: 1fr 1fr;
  }
`

const IconsListElement = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  p {
    margin: 0;
    line-height: 1.3;
    ${props => props.isDisabled && `color: #AFAAAD;`}
  }
`

const IconsListElementNote = styled.span`
  font-size: 0.734em;
`

const IconImage = styled.img`
  width: 70px;
  height: 70px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 29px;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin: 0 88px;
    padding: 0;
  }
  //desktop small
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    max-width: 639px;
    margin: 0 auto;
  }
  //desktop
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktop}px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    align-items: center;
    max-width: 1100px;
    width: 100%;
  }
`

const BodyVitals = ({ content }) => {
  const mediaType = useMediaType()
  const paragraphSizeHandler = media => {
    switch (media) {
      case "desktop":
        return 6
      case "desktopSmall":
        return "regular"
      case "tablet":
        return "regular"
      case "mobile":
        return "medium"
      default:
        return "regular"
    }
  }
  const headerSizeHandler = media => {
    switch (media) {
      case "desktop":
        return 4
      case "desktopSmall":
        return 5
      case "tablet":
        return 5
      case "mobile":
        return "medium"
      default:
        return "large"
    }
  }
  const [p, setP] = useState()
  const [h, setH] = useState()

  useEffect(() => {
    setP(paragraphSizeHandler(mediaType))
    setH(headerSizeHandler(mediaType))
  }, [mediaType])
console.log(content)
  return (
    <Container>
      <ContentWrapper>
        <TextWrapper className="content_wrapper bodyvitals">
          <HeaderWrapper>
            <HeaderText size={h}>{content.header}</HeaderText>
          </HeaderWrapper>
          <Paragraph1>
            <P size={p} color="text">
              {content.body_paragraph_1}
            </P>
          </Paragraph1>
          <Paragraph2>
            {" "}
            <P size={p} color="text">
              {content.body_paragraph_2}
            </P>
          </Paragraph2>
        </TextWrapper>
        <IconsList>
        <div className="left_block">
          
          {content.list_elements.map((el, i) => {
            const IconPath = el?.icon_path?.split("path:")[1];
            if (el.is_disabled) {
              return (
                <IconsListElement key={i} className={el.name === 'Medical Assessments' || el.name === 'Engagement & Automation' ? 'left_block_title':''}>
                  {/* <IconImage src={IconPath} alt="" /> */}
                  {IconPath && <img src={IconPath} alt="Icon" max-width="50px" />}
                  <HeaderText size={h} >
                    {el.name}
                    {/* {el?.is_disabled && (
                      <IconsListElementNote> (Research)</IconsListElementNote>
                    )} */}
                  </HeaderText>
                </IconsListElement>
              );
            }
          })}
        </div>
        <div className="left_block">
          {content.list_elements.map((el, i) => {
            const IconPath = el?.icon_path?.split("path:")[1];
            if (!el.is_disabled) {
              return (
                <IconsListElement key={i}>
                  {/* <IconImage src={IconPath} alt="" /> */}
                  {IconPath && <img src={IconPath} alt="Icon" max-width="50px" />}
                  <HeaderText size={h} className={el.name === 'Medical Assessments' || el.name === 'Engagement & Automation' ? 'left_block_title':''}>
                    {el.name}
                    {el?.is_disabled && (
                      <IconsListElementNote> (Research)</IconsListElementNote>
                    )}
                  </HeaderText>
                </IconsListElement>
              );
            }
          })}
        </div>
           
        </IconsList>

        {/* <IconsList>
          {content.list_elements.map((el, i) => {
            const IconPath = el.icon_path.split("path:")[1]

            return (
              <IconsListElement isDisabled={el.is_disabled} key={i}>
                {IconPath && <img src={IconPath} alt="Icon" max-width="50px" />}
                <HeaderText size={h}>
                  {el.name}
                  {el?.is_disabled && (
                    <IconsListElementNote> (Research)</IconsListElementNote>
                  )}
                </HeaderText>
              </IconsListElement>
            )
          })}
        </IconsList> */}
      </ContentWrapper>
    </Container>
  )
}

export default BodyVitals
