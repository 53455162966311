import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Modal } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Button from "../../atoms/Button"
import HeaderText from "../../atoms/HeaderText"
import useMediaType from "../../hooks/useMediaType"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;

  //mobile
  padding-top: 55px;
  padding-bottom: 0;
`

const HeaderWrapper = styled.div`
  z-index: 2;
  text-align: center;
  //mobile

  width: 100%;
  height: 100%;
  padding: 0 28px;

  p {
    line-height: 38px;
    margin-bottom: 30px;
  }

  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    width: 506px;

    p {
      line-height: 40px;
      margin-bottom: 1rem;
    }
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    width: 1180px;

    p {
      line-height: 48px;
    }
  }
`

const InfoText = styled.div`
  margin-bottom: 40px;
  padding: 0 28px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #1e0e5e;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 38px;
  margin: 20px 0 17px;
  justify-content: center;
`

const StyledButton = styled(Button)`
  min-width: 246px;
`

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1272px;
  flex: 1;
`

const StyledModal = styled(Modal)`
  .modal-dialog {
    justify-content: center;
    max-width: 1024px;
  }
  .modal-content {
    max-width: 95vw;
  }
`

const StyledVideoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: calc(100% / 1.78);
  background: black;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const StyledImage = styled(GatsbyImage)``

const ScrolldownHeader = ({ content }) => {
  const mediaType = useMediaType()
  const [h, setH] = useState(4)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const headerSizeHandler = media => {
    switch (media) {
      case "desktop":
        return 1
      case "desktopSmall":
        return 1
      case "tablet":
        return 3
      case "mobile":
        return 4
      default:
        return 4
    }
  }
  const image = getImage(content.video_poster)

  useEffect(() => {
    setH(headerSizeHandler(mediaType))
  }, [mediaType])

  return (
    <Container className="homesliderimage">
      <HeaderWrapper>
        <HeaderText size={h}>{content.header}</HeaderText>
      </HeaderWrapper>
      
      {/* <InfoText>*For investigational use only, not currently for sale</InfoText> */}
      <StyledModal
        show={showVideoModal}
        onHide={() => setShowVideoModal(false)}
        centered
      >
        <StyledVideoWrapper>
          <iframe
            width="100%"
            height="100%"
            src={content.video_src}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            autoPlay
          />
        </StyledVideoWrapper>
      </StyledModal>
      <VideoContainer>
        <StyledImage image={image} alt="" /> 
      </VideoContainer> 
    </Container>
  )
}

export default ScrolldownHeader
